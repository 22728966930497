import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { FC } from 'react';

import CasaLogo from '../../../../shared/logo/CasaLogo';
import BrandAndBusinessSelect from '../BrandAndBusinessSelect/BrandAndBusinessSelect';
import InstantSearch from '../InstantSearch/InstantSearch';
import UserAccount from '../UserAccount/UserAccount';
import styles from './Navbar.module.scss';

interface NavbarProps {
  onToggleSidebar: () => void;
  hideSidebar?: boolean;
}

const Navbar: FC<NavbarProps> = ({ onToggleSidebar, hideSidebar = false }) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('xl'));

  return (
    <AppBar
      id={styles.navbar}
      color='transparent'
      position='sticky'
      elevation={0}
      sx={{ bgcolor: 'white' }}
    >
      <Toolbar className={styles.navbarContainer}>
        <Box className={styles.navLeft}>
          {!hideSidebar && isMobileView && (
            <IconButton onClick={onToggleSidebar}>
              <MenuIcon />
            </IconButton>
          )}
          <CasaLogo />
        </Box>
        <Box className={styles.navRight}>
          <InstantSearch />
          <BrandAndBusinessSelect />
          <UserAccount />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
