import { Box, Drawer, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import React, { FC, useLayoutEffect } from 'react';

import useNavigationLink from '../../../../hooks/useNavigationLink';
import CasaLogo from '../../../../shared/logo/CasaLogo';
import SidebarList from '../SidebarList/SidebarList';
import styles from './Sidebar.module.scss';

interface SidebarProps {
  isSidebarActive: boolean;
  onCloseSidebar: () => void;
}

const Sidebar: FC<SidebarProps> = ({ isSidebarActive, onCloseSidebar }) => {
  const { navigationLinks, onActive } = useNavigationLink();
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('xl'));

  useLayoutEffect(() => {
    !isMobileView && onCloseSidebar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobileView]);

  const sidebarContent = (
    <Box id={styles.sidebar} component='aside'>
      <SidebarList
        list={navigationLinks}
        isParentElement
        onCloseSidebar={onCloseSidebar}
        onActive={onActive}
      />
    </Box>
  );

  if (isMobileView)
    return (
      <Drawer
        open={isSidebarActive}
        onClose={onCloseSidebar}
        PaperProps={{ className: styles.mobileSidebar }}
      >
        <Toolbar className={styles.sidebarHeader}>
          <CasaLogo />
        </Toolbar>
        {sidebarContent}
      </Drawer>
    );

  return sidebarContent;
};

export default Sidebar;
