import { Alert } from '@mui/material';
import axios from 'axios';
import {capitalize, get, includes, isEmpty} from 'lodash'
import {
  FC,
  PropsWithChildren,
  createContext,
  useEffect,
  useState,useCallback,
} from 'react'
import toast from 'react-hot-toast'
import {jwtDecode} from "jwt-decode";
import Axios from '../axios'
import Auth from '../interfaces/Auth'
import User from '../interfaces/User'
import * as keycloak from '../keycloak'
import { getPosToken, getUser } from '../services/user'
import CasaLoader from '../shared/components/CasaLoader/CasaLoader'
import { UserProvider } from './User'
import {posRoutePages} from "../routes";
import { doesRouteTokenAuthenticated } from '../services/auth'

const AuthContext = createContext<Auth>({
  currentUser: null,
})

export const AuthProvider: FC<PropsWithChildren> = ({children}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isRouteAuthenticated, setIsRouteAuthenticated] = useState(false)
  const [currentUser, setCurrentUser] = useState<User>()
  const [error, setError] = useState<string | null>(null)
  const isPosRoute = useCallback((pathName: string) => {
    for (const route of posRoutePages) {
      if (route.path === pathName) {
        return true;
      }
    }
    return false
  }, [])
  useEffect(() => {
    setIsLoading(true);
    (async () => {
      try {
        const currentPath = window.location.pathname;
        if (isPosRoute(currentPath) && includes(window.location.href, "loadTokenFromUrl")) {
          const urlSearchParams = new URLSearchParams(window.location.search);
          let decoded: string | null = urlSearchParams.get("token");
          if (decoded !== null) {
            const token =  await getPosToken(decoded)
              .then((response) => {
                const token =  get(response, "token");
                let decoded = jwtDecode(token);
                return get(decoded, 'token')
              })
            if (token !== undefined) {
              Axios.init({token: token})
              const user = await getUser()
              setCurrentUser(user)
              return;
            }
          }
        }
        const doesRouteAuthenticated = doesRouteTokenAuthenticated()
        if (doesRouteAuthenticated) return setIsRouteAuthenticated(true)

        const isAuthenticated = await keycloak.initialize();

        if (!isAuthenticated) return keycloak.redirectToLogin();

        const accessToken = keycloak.getAccessToken();

        if (!accessToken) {
          throw new Error('Authentication failed');
        }

        axios.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${accessToken}`;
        // axios.defaults.baseURL = config.ruleServerUrl; // TODO: Need to switch everything api Server

        const user = await getUser();
        setCurrentUser(user);

        toast.success(`Welcome back, ${capitalize(user.tenant.displayName)}`);
      } catch (error) {
        setError((error as Error).message);
        toast.error(`Oops! Something went wrong`);
      } finally {
        setIsLoading(false);
      }
    })()
  }, [isPosRoute])

  if (isLoading) return <CasaLoader />
  if (error) return <Alert severity="error">{error}</Alert>
  if (isRouteAuthenticated) return <>{children}</>
  if (isEmpty(currentUser))
    return <Alert severity="error">Oops! Something went wrong</Alert>

  return (
    <AuthContext.Provider value={{ currentUser }}>
      <UserProvider currentUser={currentUser}>{children}</UserProvider>
    </AuthContext.Provider>
  )
}

export default AuthContext
